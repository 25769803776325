import store from './store'

export const permissionsDirective = {
    created(el, binding) {
        // Get the required permissions from the binding value
        const requiredPermissions = binding.value
        el.style.display = 'none' // Hide the element if the user doesn't have the required permissions
        // Check if the user has the required permissions
        if (hasPermissions(requiredPermissions)) {
            el.style.display = '' // Show the element if the user has the required permissions
        }
    },
    beforeUpdate(el, binding){
        //console.log('beforeUpdate')
        const requiredPermissions = binding.value
        el.style.display = 'none' // Hide the element if the user doesn't have the required permissions
        // Check if the user has the required permissions
        //console.log(requiredPermissions)
        if (hasPermissions(requiredPermissions)) {
            el.style.display = '' // Show the element if the user has the required permissions
        }
    },
}

 // Function to check if the user has the required permissions
 function hasPermissions(requiredPermissions) {
    
    //const userPermissions = store.state.userPermissions
    const userPermissions = store.getters['user/permissions']

    //console.log('userPermissions',userPermissions)

    // Check if any of the required permissions are missing in the user's permissions
    const userPermissionsArr = userPermissions.map(obj => obj.slug)
    const hasPermission = userPermissionsArr.includes(requiredPermissions)

    return hasPermission
}