import { http } from '@/utils/http'

const state = {
    refreshKey: null,
    intervalId: null,
}

const mutations = {
    UPDATE_REFRESH_KEY(state, refreshKey) {
        state.refreshKey = refreshKey
    },
    START_INTERVAL(state, intervalId) {
        state.intervalId = intervalId
    },
    STOP_INTERVAL(state) {
        clearInterval(state.intervalId)
        state.intervalId = null
    },
}

const actions = {
    startDataUpdates({ commit, dispatch }) {
        const intervalId = setInterval(async () => {
            // Perform the database query and get the updated data
            dispatch('fetchRefreshKey')

        }, 60000) // Set the interval as needed (60,000 milliseconds = 1 minute)

        // Store the interval ID in the state
        commit('START_INTERVAL', intervalId)
    },

    async fetchRefreshKey({ commit }) {
        await http().get('activity_logs/latest')
            .then(response => {
                commit('UPDATE_REFRESH_KEY', response.data)
            })
    },

    stopDataUpdates({ commit, state }) {
        if (state.intervalId) {
            // Stop the interval if it's running
            commit('STOP_INTERVAL')
        }
    },
}

const getters = {
    refreshKey: state => state.refreshKey,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
