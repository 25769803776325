import { createStore } from 'vuex'
import { http } from '@/utils/http'
//import { find } from 'lodash'

import text from './modules/text'
import user from './modules/user'
import stores from './modules/stores'
import products from './modules/products'
import autoRefresh from './modules/autoRefresh'

export default createStore({
  state: {
    isLoading: false,
    pendingRequests: null,
    pendingOrders: null,
  },
  mutations: {
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    },
    setPendingRequests(state, pendingRequests) {
      state.pendingRequests = pendingRequests
    },
    setPendingOrders(state, pendingOrders) {
      state.pendingOrders = pendingOrders
    },
  },
  actions: {
    fetchPendingRequestsCount({ commit }) {
      //console.log('fetchPendingRequestsCount')
      http().get('/order_requests/pending_count')
        .then(response => {
          const pendingRequestsCount = response.data
          commit('setPendingRequests', pendingRequestsCount)
        })
    },
    fetchPendingOrdersCount({ commit }) {
      //console.log('fetchPendingOrdersCount')
      http().get('/orders/pending_count')
        .then(response => {
          const pendingOrdersCount = response.data
          //console.log(pendingOrdersCount)
          commit('setPendingOrders', pendingOrdersCount)
        })
    },

  },
  getters: {
    isLoading(state) {
      return state.isLoading
    },
    getPendingRequests(state) {
      return state.pendingRequests
    },
    getPendingOrders(state) {
      return state.pendingOrders
    },
  },
  modules: {
    text,
    user,
    stores,
    products,
    autoRefresh
  },
})

