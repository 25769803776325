import { http } from '@/utils/http'
import { find } from 'lodash'

const storedUser = JSON.parse(localStorage.getItem('user'))
const storedIsLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'))
const storedPermissions = JSON.parse(localStorage.getItem('permissions'))

const state = {
    user: storedUser || {
        token: null
    },
    isLoggedIn: storedIsLoggedIn || false,
    permissions: storedPermissions || [],
}

const mutations = {
    SET_USER(state, user) {
        state.user = user
        localStorage.setItem('user', JSON.stringify(user))
    },
    SET_LOGGED_IN(state, isLoggedIn) {
        state.isLoggedIn = isLoggedIn
        localStorage.setItem('isLoggedIn', JSON.stringify(isLoggedIn))
    },
    CLEAR_USER(state) {
        state.user = {
            token: null,
        }
        localStorage.removeItem('user')
        localStorage.removeItem('permissions')
    },
    SET_PERMISSIONS(state, permissions) {
        state.permissions = permissions
        localStorage.setItem('permissions', JSON.stringify(permissions))
    },
}

const actions = {

    login({ commit, dispatch }, user) {
        // Call the mutation to set the user
        // commit('setLoading', true)
        commit('SET_LOADING', true, { root: true })
        commit('SET_USER', user)
        

        // Dispatch an action to retrieve permissions
        dispatch('fetchPermissions')
        dispatch('fetchStoreData')
    },

    logout({ commit, dispatch }) {
        dispatch('postActivityLog')
        // Simulamos el cierre de sesión simplemente limpiando el estado del usuario.
        commit('CLEAR_USER')
        commit('SET_LOGGED_IN', false)
        commit('SET_PERMISSIONS', [])

        commit('stores/SET_STORE_DATA', null, { root: true })
        commit('products/SET_PRODUCTS', null, { root: true })
    },

    fetchPermissions({ commit, state }) {
        commit('SET_LOADING', true, { root: true })
        // Retrieve permissions from API
        let permissions = []
        http().get(`/user_permissions/${state.user.id}`)
            .then(response => {
                permissions = response.data
                // Call the mutation to set the permissions
                commit('SET_PERMISSIONS', permissions)
                commit('SET_LOGGED_IN', true)
                commit('SET_LOADING', false, { root: true })
            })
    },

    fetchStoreData({ commit, state, rootState }) {

        const stores = rootState.stores.data
        http().get(`/user_store/${state.user.id}`)
            .then(response => {
                const user_store = response.data
                const store = find(stores, { id: user_store.main_store_id })
                // Call the mutation to set the user_store
                commit('stores/SET_STORE_DATA', store, { root: true })
                //commit('setLoading', false)
                commit('SET_LOADING', false, { root: true })
            })

    },

    postActivityLog({ state }) {
        //console.log(state.user)
        const params = {
            user_id: parseInt(state.user.id),
            activity_type: 'ap_logout',
            details: state.user.email,
        }
        http().post('/activity_logs', params)
    },

}

const getters = {
    isAuthenticated: state => state.isLoggedIn,
    currentUser: state => state.user,
    getToken: state => state.user.token,
    permissions: state => state.permissions,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
