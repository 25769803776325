<template>
    <nav class="navbar navbar-expand navbar-light bg-transparent py-4 px-4">
        <div class="d-flex align-items-center">
            <a @click.prevent="toggleClicked()">
                <i class="fas fa-align-left primary-text fs-4 me-3"></i>
            </a>
            <h2 class="fs-2 m-0">{{ headerTitle }}</h2>
        </div>

        <div class="navbar-collapse">
            <ul class="navbar-nav ms-auto mb-lg-0">
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle second-text fw-bold" href="#" id="navbarDropdown" role="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="fas fa-user me-2"></i>
                        <span class="d-none d-md-inline">{{ username }}</span>
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end " aria-labelledby="navbarDropdown">
                        <li>
                            <router-link class="dropdown-item my-1" to="/profile">
                                <i class="far fa-user me-2"></i>{{ text.profile }}
                                <span class="d-md-none">({{ username }})</span>
                            </router-link>
                            <router-link class="dropdown-item my-1" to="/organization">
                                <i class="fas fa-sitemap me-2"></i>{{ text.company }}
                            </router-link>
                        </li>
                        <li>
                            <a @click="switchStore()" class="dropdown-item my-1 text-primary">
                                <i class="fas fa-right-left me-2"></i>{{ text.switchStore }}
                            </a>
                        </li>
                        <li>
                            <a @click="logout" class="logout dropdown-item my-1 text-danger">
                                <i class="fas fa-sign-out-alt me-2"></i>{{ text.logout }}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>

    </nav>
</template>

<script>
import { defineComponent } from 'vue'
import { mapState, mapGetters } from 'vuex'

export default defineComponent({
    name: 'HeaderBar',
    components: {},
    props: {
        headerTitle: {
            type: String,
            default: null
        }
    },
    watch: {
        headerTitle() {
            this.hideUserame()
        }
    },
    data() {
        return {
            username: '',
        }
    },
    computed: {
        ...mapState('text', ['text']),
        ...mapGetters('user', ['currentUser']),
    },
    methods: {
        toggleClicked() {
            this.$emit('toggleClicked')
        },
        hideUserame() {

        },
        logout() {
            //this.$store.dispatch('logout')
            this.$store.dispatch('user/logout')
            this.$store.dispatch('autoRefresh/stopDataUpdates')
            this.$router.push('/login')
        },
        switchStore() {
            this.$emit('switchStore')
            //this.$store.dispatch('saveState');
        }
    },
    mounted() {
        //this.username = this.$store.state.user.username
        this.username = this.currentUser.username
    }
})

</script>