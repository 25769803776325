import { http } from '@/utils/http'

const storedStores = JSON.parse(localStorage.getItem('stores'))
const storedCurrentStore = JSON.parse(localStorage.getItem('currentStore'))

const state = {
    data: storedStores || [],
    currentStore: storedCurrentStore || null,
}

const mutations = {
    SET_STORES(state, stores) {
        state.data = stores
        localStorage.setItem('stores', JSON.stringify(stores))
    },
    SET_STORE_DATA(state, store) {
        //console.log('SET_STORE_DATA',store)
        state.currentStore = store
        localStorage.setItem('currentStore', JSON.stringify(store))
    },
}

const actions = {
    fetchStores({ commit }) {
        // commit('setLoading', true)
        // Retrieve stores from API
        let stores = []
        http().get('/stores')
            .then(response => {
                stores = response.data
                commit('SET_STORES', stores)
            })
    },
}

const getters = {
    stores(state) {
        return state.data
    },
    currentStore(state) {
        return state.currentStore
    },
    currentStoreId(state) {
        if (state.currentStore) {
            return state.currentStore.id
        } else {
            return 0
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
