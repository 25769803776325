<template>
  <transition>
    <div v-if="!isAuthenticated">
      <login-view />
    </div>
  </transition>

  <div v-if="isAuthenticated" class="d-flex" :class="{ toggled: menuToggled }" id="wrapper">
    <side-bar :routeName="routeName" :refresh-store="refreshStore" :show-menu-list="showMenuList" />
    <div id="page-content-wrapper">
      <header-bar :headerTitle="headerTitle" @toggleClicked="toggleClicked" @switchStore="switchStore" />
      <router-view />
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar.vue'
import HeaderBar from '@/components/HeaderBar.vue'
import LoginView from '@/views/LoginView.vue'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
//import { http } from '@/utils/http'
import { find } from 'lodash'
import 'vue3-toastify/dist/index.css'

export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        //this.$store.dispatch('fetchPendingRequestsCount')
        //this.$store.dispatch('fetchPendingOrdersCount')
        this.routeName = to.name || ''
        this.headerTitle = this.text.headersKv.find(({ key }) => key === to.name)?.value ?? ''
      }
    },
    routeName() {
      if (window.innerWidth < 768) {
        this.menuToggled = false
      }
    },
    currentStoreId(){
      this.refreshStore = new Date().toISOString()
    }
  },
  components: {
    SideBar,
    HeaderBar,
    LoginView
  },
  data() {
    return {
      menuToggled: false,
      routeName: null,
      headerTitle: null,
      refreshStore: '',
      showMenuList: false
    }
  },
  computed: {
    ...mapState('text', ['text']),
    ...mapGetters('user', ['isAuthenticated']),
    ...mapGetters('stores',['currentStoreId']),
    ...mapGetters('stores',['stores']),
    ...mapGetters('products',['products']),
    ...mapGetters(['isLoading']),
  },
  methods: {
    toggleClicked() {
      this.menuToggled = !this.menuToggled
    },
    switchStore() {
      if (this.currentStoreId == 1) {
        this.setStore(2)
      } else {
        this.setStore(1)
      }
      this.refreshStore = new Date().toISOString()
    },
    setStore(storeId) {
      const store = find(this.stores, { id: storeId })
      this.$store.commit('stores/SET_STORE_DATA', store)
    },
    handleStorageChange(event){
      //console.log(event.key)
      if (event.key === 'isLoggedIn') {
        // Respond to the local storage change here
        if(!JSON.parse(event.newValue)){
          this.$store.dispatch('user/logout')
          this.$router.push('/login')
        }
      }
      if (event.key === 'currentStore') {
        // Respond to the local storage change here
        this.$store.commit('stores/SET_STORE_DATA', JSON.parse(event.newValue))

      }
    },
  },
  created() {
    const route = useRoute();
    this.routeName = computed(() => route.name).value
    this.headerTitle = this.text.headersKv.find(({ key }) => key === this.routeName)?.value ?? ''
    if (window.innerWidth < 768) {
      this.menuToggled = false
    }
    window.addEventListener('storage', this.handleStorageChange)
  },
  mounted() {
    if(!this.stores.length){
      this.$store.dispatch('stores/fetchStores')
    }
    if(!this.products.length){
      this.$store.dispatch('products/fetchProducts')
    }
  }
}

</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity .4s ease
}

.v-enter-from,
.v-leave-to {
  opacity: 0
}
</style>