import { http } from '@/utils/http'

const storedProducts = JSON.parse(localStorage.getItem('products'))

const state = {
    products: storedProducts || [],
}

const mutations = {
    SET_PRODUCTS(state, products) {
        state.products = products
        localStorage.setItem('products', JSON.stringify(products))
    },
}

const actions = {
    fetchProducts({ commit }) {
        //commit('SET_LOADING', true, { root: true })
        // Retrieve stores from API
        let products = []
        http().get('/products')
            .then(response => {
                products = response.data
                //console.log(products)
                commit('SET_PRODUCTS', products)
                //commit('SET_LOADING', false, { root: true })
            })
    },
}

const getters = {
    products(state) {
        return state.products
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
