import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far, fab)
dom.watch();

import { toast } from 'vue3-toastify'

import { permissionsDirective } from '@/directives.js'

import { http } from '@/utils/http'

/* Default title tag */
store.dispatch('text/setLanguage', { language: 'es' })

/* Styles */
import '@/assets/css/main.css'

const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router)

// Definir una variable global
//position: toast.POSITION.BOTTOM_CENTER,
app.config.globalProperties.$toastOptions = {
    autoClose: 2500,
    position: toast.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    transition: toast.TRANSITIONS.SLIDE,
    theme: 'colored',
    icon: true,
    newestOnTop: true,
    pauseOnFocusLoss: false,
    clearOnUrlChange: false,
}

app.config.globalProperties.$postActivityLog = function (pass_at, pass_details) {
    //console.log(store)
    const params = {
        user_id: store.getters['user/currentUser'].id,
        activity_type: pass_at,
        details: pass_details,
    }
    http().post('/activity_logs', params)
}

app.directive('permission', permissionsDirective)

app.mount('#app')

export { app }
