<template>

    <div class="login-container">

        <div class="login">

            <h1 class="text-center">Inicia Sesión</h1>
    
            <form @submit.prevent="Submit">
                <div class="form-group">
                    <label for="inputEmail" class="form-label">Email</label>
                    <input type="email" class="form-control" id="inputEmail" v-model="form.email" required>
                </div>
                <div class="form-group">
                    <label for="inputPassword" class="form-label">Contraseña</label>
                    <input type="password" class="form-control" id="inputPassword" v-model="form.password" required>
                </div>
                <button type="submit" class="btn btn-primary w-100">Enviar</button>
            </form>
    
        </div>

    </div>
    
  </template>
  
  <script>
    import { defineComponent } from 'vue'
    import { http } from '@/utils/http'

    export default defineComponent({
        data () {
            return {
                form: {
                    email:"",
                    password:""
                },
            }
        },
        watch: {
            
        },
        methods: {
            async Submit () {
                const payload = {
                    'email': this.form.email,
                    'password': this.form.password,
                }
                await http().post('admin_panel_login', payload)
                .then(response => {
                    const result = response.data
                    const user = {
                        'id': result.user.id,
                        'email': result.user.email,
                        'username': result.user.name,
                        'token': result.token,
                        'employee_id': result.user.employee_id,
                    }
                    this.$store.dispatch('user/login', user)
                    this.$store.dispatch('products/fetchProducts')
                    this.$router.push('/')

                })
                .catch(error => {
                    alert(error.message)
                })

            }
        },
        created () {
        },
    })
</script>

<style>

    .login-container {
        height: 100vh;
        display:flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(
            to right,
            #198754,   
            #009d63,   
            #39c28f
        );
    }

    .login {
        width: 360px;
        height: min-content;
        padding: 20px;
        border-radius: 12px;
        background: #fff;
    }

    .login h1 {
        font-size: 36px;
        margin-bottom: 25px;
    }

    .login form{
        font-size: 20px;
    }

    .login form .form-group{
        margin-bottom: 12px;
    }

    .login form button[type="submit"]{
        font-size: 20px;
        margin-top: 15px;
    }

</style>